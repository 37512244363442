
.course-item[data-v-31b52d52] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: auto;
    margin-bottom: 15px;
}
.course-item img[data-v-31b52d52] {
    width: 240px;
    height: 150px;
    margin-right: 20px;
    -o-object-fit: cover;
       object-fit: cover;
}
.course-date[data-v-31b52d52] {
    font-weight: bold;
}
.course-infos[data-v-31b52d52] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    height: 150px;
}
.course-infos h3[data-v-31b52d52] {
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.course-infos p[data-v-31b52d52] {
    font-size: 14px;
    color: #666;
}
.course-infos span[data-v-31b52d52] {
    color: #666;
    font-size: 12px;
}
.classes-wrap ul[data-v-31b52d52]{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.classes-item img[data-v-31b52d52]{
    width: 100%;
    height: 142px;
    -o-object-fit: cover;
       object-fit: cover;
}
.classes-item[data-v-31b52d52] {
    margin-right: 6px;
    width: 240px;
    margin-bottom: 12px;
    cursor: pointer;
    border-radius: 4px;
    overflow: hidden;
}
.classes-item[data-v-31b52d52]:nth-child(4n) {
    margin-right: 0;
}
.classes-infos[data-v-31b52d52] {
    max-height: 90px;
    min-height: 90px;
    padding:4px;
    background: #FAFAFA;
}
.classes-infos h3[data-v-31b52d52] {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    font-size: 14px;
    height: 14px;
    line-height: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
}
.classes-infos span[data-v-31b52d52] {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    font-size: 12px;
    color: #666;
    display: block;
    line-height: 12px;
    margin-bottom: 16px;
    text-align: left;
}
.classes-item .progress[data-v-31b52d52] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.classes-item .progress>span[data-v-31b52d52] {
    margin-right: 20px;
}
.prev_course[data-v-31b52d52] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #F3F6F7;
    padding: 10px 30px 10px 10px;
    margin-bottom: 30px;
    border-radius: 4px;
    margin-left: 28px;
}
.prev_course img[data-v-31b52d52] {
    width: 240px;
    height: 150px;
    margin-right: 20px;
}
.prev_course>div[data-v-31b52d52] {
    height: 150px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -ms-flex-pack: distribute;
        justify-content: space-around;
}
.prev_course>div h3[data-v-31b52d52] {
    font-size: 16px;
    font-weight: bold;
}
.prev_course>div p[data-v-31b52d52] {
    font-size: 14px;
    color: #666;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
.prev_course>div span[data-v-31b52d52] {
    font-size: 12px;
    color: #666;
}
.prev_course button[data-v-31b52d52] {
    background: #FF8611;
    color: #fff;
    border-color: #FF8611;
}
