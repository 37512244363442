
.rIcon[data-v-1c7604c7]{
    background: #5cb85c;
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: inline-block;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    margin-left: 10px;
}
.wIcon[data-v-1c7604c7] {
    background: red;
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: inline-block;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    margin-left: 10px;
}
button[data-v-1c7604c7] {
    color: #fff!important;
}
.answerPaper[data-v-1c7604c7] {
    width: 1000px;
    margin: 0 auto;
    text-align: left;
}
.answerPaper .title[data-v-1c7604c7] {
    padding: 20px 0;
    font-size: 18px;
    text-align: center;
    color: #fff;
}
.answerPaper .titleWrap[data-v-1c7604c7] {
    height: 120px;
    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.answerPaper .itemTitle[data-v-1c7604c7] {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: bold;
}
.answerPaper .score[data-v-1c7604c7] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #fff;
}
.answerPaper .score span[data-v-1c7604c7] {
    font-size: 16px;
}
.answerPaper .answerList li[data-v-1c7604c7] {
    margin-bottom: 20px;
}
.answerPaper .answerList li .itemWrap div[data-v-1c7604c7] {
    padding: 5px 20px;
}
.answerPaper .btn[data-v-1c7604c7] {
    padding: 30px 0;
    text-align: center;
}
@media screen and (max-width: 1024px) {
.answerPaper[data-v-1c7604c7] {
        width: 100%;
}
}
