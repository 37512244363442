
.study .listWrap[data-v-0ceabae2] {
  height: 100%;
}
@media screen and (max-width: 1024px) {
.study .listWrap[data-v-0ceabae2] {
    width: 100%;
}
}

