
.top[data-v-734bac76] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.classes-bg[data-v-734bac76] {
  background: url(../img/class-bg.5f71341c.png) no-repeat;
  background-size: cover;
  padding: 40px 0;
}
.newbg[data-v-734bac76]{
    background: url(../img/class_newbg.ecc6569c.jpg) no-repeat;
    background-size: cover;
    padding: 40px 0;
}
.inner[data-v-734bac76] {
  width: 100vw;
  margin: 0 auto;
}
.top[data-v-734bac76] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.title-wrap[data-v-734bac76] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-width: 200px;
}
.title-wrap img[data-v-734bac76] {
  width: 54px;
  height: 54px;
  margin-right: 15px;
}
.title-wrap h3[data-v-734bac76] {
  font-size: 30px;
  margin-bottom: 0;
  background: -webkit-gradient(linear, left top, right top, from(#DCFBFE), to(#6EA2DF));
  background: linear-gradient(to right, #DCFBFE, #6EA2DF);
  /* -webkit-background-clip: text; */
  color: transparent;
}
.search[data-v-734bac76] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* justify-content: center; */
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 4px;
  height: 30px;
  width: 244px;
  margin-right: 24px;
  background: #F3F3F3;
  overflow: hidden;
}
.search-msg[data-v-734bac76] {
  /* width: 400px; */
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background: #F3F3F3;
  height: 30px;
  border: none;
  border-radius: 0;
}
.search-btn[data-v-734bac76] {
  width: 30px;
  height: 30px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAABuElEQVRIS+3STUsbURQG4PeMITAUF1kWxI1V6MKlqxZ0IQr+gAidjzsJohQKXYmlAcGNH0txp4swMxnB+QNKoI0KuhH/gSAKXZVSUhcZmuQemYUQIsgdGM3CzPrlPHPeewg9+qhHLvrwizXfr7pf9bM1kOi4wjDMRtH/ZWYuADQC4B+AI03jkmVZV0n+UhmO0UajeQjwJBEFUuKMCEMAFwEMSonpQsG8UMWVYc+rlJixxow5xzGrD0AQBLlWi38CeKPr2ff5fL6tgivDrutfEWlntm2I7sGetz/JLI+JtCnb/nSSMlxhZlpyHGO3e3CtVsvc3v5qEmHRts29tOE6QNtCGKuPN/aGmbUbZp53HCtMGz4A8CGToXHDMP52DnfdYAfghXY7O1ws5n+nCvu+/05KugRwTaR9bTTuznVdf8s8sAzwFwBVIcxZFTTOKB9XHC6XKxOahgDAaAcQATgFMAPwlhDWNxU8ERwPDMNwIIpaHwE5JiXXieQPIcQf1/U3AVpRxRPDT23zgBNhw7bN709lU4VjyPMq68z4LISZe1FY5X0TH5fqUJVc6lWroP2NVVtKJff63vgehGaeH8fsfYIAAAAASUVORK5CYII=) no-repeat;
  border: none;
  border-radius: 0;
}
.classes-content[data-v-734bac76] {
  padding: 13px 0 40px;
  width: 100vw;
  margin: 0 auto;
  min-height: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.lesson-list[data-v-734bac76] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.lesson-item[data-v-734bac76] {
  width: 33.3vw;
  height: auto;
  /* margin-right: 21px; */
  background: #fff;
  /* margin-bottom: 20px; */
  padding: 1vw;
}
.lesson-item .content[data-v-734bac76] {
  background: #FAFAFA;
  text-align: left;
  padding: 1vw;
  cursor: pointer;
}
.lesson-item .content[data-v-734bac76]:hover {
  -webkit-box-shadow: 0 0 5px #ddd;
          box-shadow: 0 0 5px #ddd;
}
.lesson-item .content img[data-v-734bac76] {
  height: 16vw;
  -o-object-fit: cover;
     object-fit: cover;
  margin-bottom: 10px;
  border-radius: 4px;
}
.lesson-item4[data-v-734bac76] {
  width: 25vw;
  height: auto;
  /* margin-right: 21px; */
  background: #fff;
  /* margin-bottom: 20px; */
  padding: 0.75vw;
}
.lesson-item4 .content[data-v-734bac76] {
  background: #FAFAFA;
  text-align: left;
  padding: 0.75vw;
  cursor: pointer;
}
.lesson-item4 .content[data-v-734bac76]:hover {
  -webkit-box-shadow: 0 0 5px #ddd;
          box-shadow: 0 0 5px #ddd;
}
.lesson-item4 .content img[data-v-734bac76] {
  height: 12vw;
  -o-object-fit: cover;
     object-fit: cover;
  margin-bottom: 10px;
  border-radius: 4px;
}
.lesson-item5[data-v-734bac76] {
  width: 20vw;
  height: auto;
  /* margin-right: 21px; */
  background: #fff;
  /* margin-bottom: 20px; */
  padding: 0.6vw;
}
.lesson-item5 .content[data-v-734bac76] {
  background: #FAFAFA;
  text-align: left;
  padding: 0.6vw;
  cursor: pointer;
}
.lesson-item5 .content[data-v-734bac76]:hover {
  -webkit-box-shadow: 0 0 5px #ddd;
          box-shadow: 0 0 5px #ddd;
}
.lesson-item5 .content img[data-v-734bac76] {
  height: 10vw;
  -o-object-fit: cover;
     object-fit: cover;
  margin-bottom: 10px;
  border-radius: 4px;
}
.lesson-item6[data-v-734bac76] {
  width: 16.6vw;
  height: auto;
  /* margin-right: 21px; */
  background: #fff;
  /* margin-bottom: 20px; */
  padding: 0.5vw;
}
.lesson-item6 .content[data-v-734bac76] {
  background: #FAFAFA;
  text-align: left;
  padding: 0.5vw;
  cursor: pointer;
}
.lesson-item6 .content[data-v-734bac76]:hover {
  -webkit-box-shadow: 0 0 5px #ddd;
          box-shadow: 0 0 5px #ddd;
}
.lesson-item6 .content img[data-v-734bac76] {
  height: 8vw;
  -o-object-fit: cover;
     object-fit: cover;
  margin-bottom: 10px;
  border-radius: 4px;
}
.lesson-title[data-v-734bac76] {
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 24px;
  height: 24px;
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.lesson-about[data-v-734bac76] {
  margin-bottom: 22px;
  font-size: 12px;
  line-height: 20px;
  color: #727275;
}

/* .lesson-name {
  min-height: 18px;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 18px;
  height: 18px;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
} */
.lesson-price-info span[data-v-734bac76] {
  /* /* height: 12px; */
  line-height: 18px;
  font-size: 12px;
  color: #727275
}
.lesson-price-info[data-v-734bac76] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.lesson-msg-wrap[data-v-734bac76] {
  background: #FAFAFA;
}
.lesson-price-sign[data-v-734bac76] {
  color: #FF5E57 !important;
}
.lesson-price[data-v-734bac76] {
  color: #FF9B38 !important;
  font-size: 16px !important;
  /* line-height: 18px; */
  font-weight: bold;
}
.bot[data-v-734bac76] {
  /* box-shadow: 0 3px 5px #ccc; */
}
.bot .first-menus[data-v-734bac76] {
  min-height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-transition: 1s all;
  transition: 1s all;
  /* padding-top: 18px; */
  padding: 10px 0px;
  border-bottom: 1px solid #F3F3F3;
  border-top: 1px solid #F3F3F3;
}
.bot .first-menus .notactive[data-v-734bac76] {

  color: #434347;
  /* font-weight: bold; */
  font-size: 14px;
  margin-right: 34px;
  /* margin-bottom: 18px; */
  cursor: pointer;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.bot .first-menus .notactive[data-v-734bac76]::after {
  content: "";
  width: 30px;
  height: 2px;
  margin: 0 auto;
  display: block;
  border: 2px solid #fff;
}
.bot .first-menus .active[data-v-734bac76] {

  color: #0063B1;
  /* font-weight: bold; */
  font-size: 14px;
  margin-right: 34px;
  /* margin-bottom: 18px; */
  cursor: pointer;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.bot .first-menus .active[data-v-734bac76]::after {
  content: "";
  width: 30px;
  height: 2px;
  margin: 0 auto;
  display: block;
  border: 2px solid #0063B1;
}
.second-menus[data-v-734bac76] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-transition: 1s all;
  transition: 1s all;
  /* border-bottom: 1px solid #ddd; */
  /* margin-bottom: 20px; */
}
.second-menus li[data-v-734bac76] {
  padding: 0 10px;
  /* height: 36px; */
  background: #fff;
  color: #000;
  font-size: 14px;
  margin-right: 15px;
  margin-bottom: 5px;
  cursor: pointer;
  line-height: 28px;
  text-align: left;
}
.third-menus[data-v-734bac76] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-transition: 1s all;
  transition: 1s all;
  margin-bottom: 20px;
}
.third-menus li[data-v-734bac76] {
  padding: 0 10px;
  /* height: 36px; */
  background: #fff;
  color: #333;
  font-size: 14px;
  margin-right: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  line-height: 36px;
  text-align: left;
}
.loadding[data-v-734bac76] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.ac-bg[data-v-734bac76] {
  color: #fff !important;
  background: #0063B1 !important;
  border-radius: 4px;
  /* font-weight: bold; */
}
.ac[data-v-734bac76] {
  color: #0063B1 !important;
  /* font-weight: bold; */
}
.no[data-v-734bac76] {
  min-height: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.no img[data-v-734bac76] {
  width: 100px;
  height: 100px;
}
.no p[data-v-734bac76] {
  color: #bbb;
}
.menu-more[data-v-734bac76] {
  color: #666;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.menu-more img[data-v-734bac76] {
  width: 28px;
  height: 26px;
  padding: 5px;
  cursor: pointer;
}
