
button[data-v-51b535c6] {
    color: #fff!important;
}
.examPaper[data-v-51b535c6] {
    width: 1000px;
    margin: 0 auto;
    text-align: left;
}
.examPaper .examItem[data-v-51b535c6] {
    margin-bottom: 20px;
}
.examPaper .title[data-v-51b535c6] {
    padding: 20px 0;
    font-size: 18px;
    text-align: center;
    color: #fff;
}
.examPaper .itemTitle[data-v-51b535c6] {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
}
.examPaper .btnWrap[data-v-51b535c6] {
    text-align: center;
    padding: 30px 0;
}
.examPaper .titleWrap[data-v-51b535c6] {
    height: 120px;
    border-radius: 4px;
    margin-bottom: 20px;
}
.examPaper .score[data-v-51b535c6] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #fff;
}
.examPaper .score span[data-v-51b535c6] {
    font-size: 16px;
}
@media screen and (max-width: 1024px) {
.examPaper[data-v-51b535c6] {
        width: 100%;
}
}
