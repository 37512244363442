
.bg[data-v-30dafc62] {
    width: 100%;
    height: 760px;
    background: url(../img/bankIndexBg.1f143882.jpg) no-repeat center center;
    background-size: cover;
}
.inner[data-v-30dafc62] {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding-bottom: 60px;
}
.inner p[data-v-30dafc62] {
    width: 490px;
    color: #fff;
    font-size: 14px;
    text-align: left;
    line-height: 24px;
    margin-bottom: 70px;
}
.btn[data-v-30dafc62] {
    widows: 160px;
    height: 46px;
    border-radius: 10px;
    background: #E99F0E;
    font-size: 15px;
    color: #fff;
    border: none;
}
.bg-title[data-v-30dafc62] {
    font-size: 50px;
    color: #fff;
    font-weight: 500;
}
.bg-subt[data-v-30dafc62] {
    font-size: 38px;
    background-image: -webkit-linear-gradient(top, #fff, #91bbe4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
