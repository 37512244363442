
.top[data-v-529c0c16] {
    width: 100%;
    position: fixed;
    height: 80px;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 99999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.top h3[data-v-529c0c16], .top h6[data-v-529c0c16] {
    font-weight: bold;
}
.talkingRoom[data-v-529c0c16] {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}
.talkingRoom .pho[data-v-529c0c16] {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;
}
.talkingInfo .name[data-v-529c0c16] {
    font-weight: bold;
    line-height: 14px;
    padding: 18px 10px;
    display: block;
}
.talkingInfo .txt[data-v-529c0c16] {
    background: rgb(8, 128, 241);
    color: #fff;
    border-radius: 6px;
    text-align: left;
    padding: 10px 15px;
    margin-bottom: 0;
}
.talkingItem[data-v-529c0c16] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin-bottom: 20px;
}
.talkingInfo[data-v-529c0c16] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.msg[data-v-529c0c16] {
    padding: 45px 0 0;
    border-top: 1px solid #ddd;
    color: #888;
}
.btn[data-v-529c0c16] {
    background: rgb(8, 128, 241);
    color: #fff;
    font-size: 12px;
    height: 30px;
    position: fixed;
    z-index: 9999999;
    right: 5px;
    bottom: 80px;
    border-radius: 15px;
    border: none;
}
