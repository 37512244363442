
button[data-v-2ba46ad4] {
  color: #fff!important;
  border: none;
}
.resetBtn[data-v-2ba46ad4] {
  background: #fff;
  color: #333!important;
  border: 1px solid #bbb;
}
.setpassword[data-v-2ba46ad4] {
    padding: 20px;
    border: 1px solid #eee;
    min-height: 500px;
}
.setpassword .content[data-v-2ba46ad4] {
    width: 320px;
    margin: 50px 0 0 50px;
}
.setpassword .title[data-v-2ba46ad4] {
    border-left: 4px solid #fff;
    text-align: left;
    padding-left: 10px;
    font-weight: bold;
    line-height: 20px;
    font-size: 16px;
}
.setpassword label[data-v-2ba46ad4] {
    min-width: 80px;
    height: 40px;
    line-height: 40px;
}
.setpassword .item[data-v-2ba46ad4] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 30px;
}
.setpassword .item input[data-v-2ba46ad4] {
    width: 240px;
    text-align: left;
    height: 40px;
}
.setpassword .do button[data-v-2ba46ad4]:first-child {
    margin-right: 20px;
}
@media screen and (max-width: 1024px) {
.setpassword[data-v-2ba46ad4] {
      min-height: 330px;
}
.setpassword .content[data-v-2ba46ad4] {
      width: 100%;
      margin: 0;
}
.setpassword .title[data-v-2ba46ad4] {
      font-size: 16px;
      margin-bottom: 20px;
}
}
