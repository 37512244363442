
.wechat[data-v-d889957a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.checkPhone[data-v-d889957a] {
    padding: 30px;
    -webkit-box-shadow: 0 0 3px #ddd;
            box-shadow: 0 0 3px #ddd;
    border-radius: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
/* .checkPhone .txt {
    color: #000;
    font-weight: bold;
    padding: 0 0 30px 0;
    margin-bottom: 0;
} */
.next[data-v-d889957a] {
    margin-top: 10px;
}
.formItem[data-v-d889957a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 20px;
    width: 100%;
}
.codeNumber[data-v-d889957a] {
    width: 140px;
}
.formItem label[data-v-d889957a] {
    min-width: 80px;
    text-align: left;
    margin-right: 10px;
}
.checkItem[data-v-d889957a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.checkItem input[data-v-d889957a] {
    height: 40px;
}
#captchaBoxBtn2[data-v-d889957a] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    height: 40px;
}
.sendBtn[data-v-d889957a] {
    margin-left: 10px;
    font-size: 14px;
    min-width: 102px;
}
#sc[data-v-d889957a] {
  margin: 0;
}
.nc_scale[data-v-d889957a] {
  background: #e8e8e8 !important;
}
.nc_scale div.bc_bg[data-v-d889957a] {
  background: #7ac23c !important;
}
.nc_scale .scale_text2[data-v-d889957a] {
  color: #fff !important;
}
.nc_scale span[data-v-d889957a] {
  border: 1px solid #ccc !important;
}
.errloading[data-v-d889957a] {
  border: #faf1d5 1px solid !important;
  color: #ef9f06 !important;
}
