
button[data-v-1e41987c] {
  width: 120px;
  font-size: 14px;
}
.lso[data-v-1e41987c] {
  width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.listOne[data-v-1e41987c] {
  padding: 0 25px;
  border: 1px solid #eee;
  margin-bottom: 20px;
}
.listOne .tcItem[data-v-1e41987c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 240px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 25px 0;
  border-bottom: 1px solid #eee;
}
.listOne .tcItem[data-v-1e41987c]:last-child {
  border-bottom: none;
}
.loadding[data-v-1e41987c] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.listOne .img[data-v-1e41987c] {
  width: 330px;
  height: 190px;
  margin-right: 20px;
  overflow: hidden;
  border: 1px solid #eee;
  border-radius: 4px;
  position: relative;
}
.listOne .img:hover img[data-v-1e41987c] {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.listOne .img img[data-v-1e41987c] {
  -webkit-transition: all .3s;
  transition: all .3s;
  -o-object-fit: cover;
     object-fit: cover;
}
.listOne .info[data-v-1e41987c] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.listOne .top[data-v-1e41987c] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
  text-align: left;
}
.listOne .bot[data-v-1e41987c] {
  width: 100%;
  text-align: right;
}
.listOne .title[data-v-1e41987c] {
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  height: 18px;
  cursor: pointer;
      display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #000;
    margin-bottom: 20px;
}
.listOne .describe[data-v-1e41987c] {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  color: #666;
  line-height: 22px;
}
.listOne .classTxt[data-v-1e41987c] {
  color: #666;
}
.menuWrap[data-v-1e41987c] {
  border: 1px solid #eee;
  padding: 25px 25px 10px 25px;
  margin-bottom: 20px;
  border-radius: 4px;
}
.menu[data-v-1e41987c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 10px;
}
.menu span[data-v-1e41987c] {
  min-width: 70px;
  height: 20px;
  line-height: 20px;
}
.menu ul[data-v-1e41987c]{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 0;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow: hidden;
  -webkit-transition: all .8s;
  transition: all .8s;
}
.first-menus[data-v-1e41987c] {
  max-height: 120px;
}
.menu-show-style[data-v-1e41987c] {
  max-height: 1000px!important;
}
.menu ul li[data-v-1e41987c] {
  margin: 0 10px 10px;
  line-height: 14px;
  cursor: pointer;
  line-height: 20px;
  /* height: 20px; */
  color: #666;
  text-align: left;
  /* overflow: hidden;
  max-width: 180px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden; */
}
.icon[data-v-1e41987c] {
    background: rgb(255, 134, 17);
    color: #fff!important;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 8px;
    border-radius: 4px;
}
.search[data-v-1e41987c] {
    width: 500px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 20px;
}
.search>input[data-v-1e41987c] {
    margin-right: 10px;
    height: 40px;
    border-color: #eee;
}
.search>button[data-v-1e41987c] {
    height: 40px;
}
.no[data-v-1e41987c] {
    min-height: 400px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.no img[data-v-1e41987c] {
    width: 100px;
    height: 100px;
}
.no p[data-v-1e41987c] {
    color: #bbb;
}
@media screen and (max-width: 1024px) {
.listOne .tcItem[data-v-1e41987c] {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: auto;
}
.listOne .img[data-v-1e41987c] {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
}
.listOne .info[data-v-1e41987c] {
    width: 100%;
}
.listOne[data-v-1e41987c] {
    padding: 0 10px;
}
.search[data-v-1e41987c] {
    width: 100%;
}
}
@media screen and (min-width:768px) and (max-width:1024px){
.listOne .img[data-v-1e41987c] {
    height: 420px;
}
}
