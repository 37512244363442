
.classes-bg[data-v-2dc40be7] {
    background: url(../img/class-bg.5f71341c.png) no-repeat;
    background-size: cover;
    padding: 40px 0;
}
.newbg[data-v-2dc40be7]{
    background: url(../img/class_newbg.ecc6569c.jpg) no-repeat;
    background-size: cover;
    padding: 40px 0;
}
.inner[data-v-2dc40be7] {
    width: 1200px;
    margin: 0 auto;
}
.top[data-v-2dc40be7] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.title-wrap[data-v-2dc40be7] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-width: 200px;
}
.title-wrap img[data-v-2dc40be7] {
    width: 54px;
    height: 54px;
    margin-right: 15px;
}
.title-wrap h3[data-v-2dc40be7] {
    font-size: 30px;
    margin-bottom: 0;
    background: -webkit-gradient(linear, left top, right top, from(#DCFBFE), to(#6EA2DF));
    background: linear-gradient(to right, #DCFBFE, #6EA2DF);
    -webkit-background-clip: text;
    color: transparent;
}
.search[data-v-2dc40be7] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 2px;
    overflow: hidden;
}
.search-msg[data-v-2dc40be7] {
    width: 400px;
    height: 50px;
    border-radius: 0;
}
.search-btn[data-v-2dc40be7] {
    width: 50px;
    height: 50px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAC2UlEQVRoQ+2ZW0hUURSGv6PWIFghMiKVWRBi+pIGZVRmBQkFZYFGPVjaZE/1EFhBICIRdMGHekqtAYkuE3SDgkystMAuo/TgBQlEURAvRCWZ14nN6UQNKTP7nIOn2APnZdh7sb71/2uftWe0wLnYAP/BR1MgDlNRKeIwQVCKKEVsqoCylk2FlQ6rFJEunU0blSJ/LWzEPEjeoT8J6RCToC8b6Yf+Fuh8oj/TE5brYp0iyTthaznErpg9yU9dUF8KnY8thTEPokVAdimsOwaaBoPt8OEGdNXDl14IBGBhIizfDOmHwJ2if/fmCrwoh8C0JUDmQbaUQeZxmBqHujPQ4p0lOQ0yimDbWYhyQdNleF7mABBhp701uudv50FPY2hJJWVB/h2InA/3CiyxmbwiorGLm/SeeFoCzddCgzBWZXgg5wKInqnMNH0AyIOk7IY9Xhhog+tZEl7X4HAjxKfC/ULoeBheIYJWy4PsugppefDsNLyvlEtizRHYfh5a78Kjo3Ixfu6SByl+C3EroWoDDLXLJeFOBc8rGP4IlWvlYpgGOdEDrhi4uAQmR+WSiIqGkj4YG4GKZXIxzIN0g2sBXFoKE9/kkvgF8hUqkuRimAYxrFW9EQbb5JJwrwLP6zm2ltHstafAXyUH4ohmN45fMZIIVQjzB0sx2hQ1OOD4/f2FWHsS/NXhqeKYF6JI2xhRpsbAtx+6X4YGk7QJ8n0OGVGMlI2hcfK7Pp77xagyg82EndILHTg0CpjgMX6oA5q90N0An3t1qEVijM+G1QUgTipjjE9cD1ok+PbB6HBoas6wSv7NHhxQ5mJ1sA4WZ8BAK9zMNQVjHYgAC/eqGx0HBx5AfJppGGtBZMxhEczcgwh4C2CcARIM0/cOanLC0tc5IAaMuAKPj8Ct3H8YJKzU/1zsLEUUCOqvNxMmsGer6hF76iofVSkiXzt7dipF7KmrfFSliHzt7Nn5A8ebZlwZAX9GAAAAAElFTkSuQmCC) no-repeat;
    border: none;
    border-radius: 0;
}
.classes-content[data-v-2dc40be7] {
    padding: 30px 0 40px;
    width: 1200px;
    margin: 0 auto;
    min-height: 400px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.lesson-list[data-v-2dc40be7] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.lesson-item[data-v-2dc40be7] {
    width: 386px;
    height: 356px;
    margin-right: 21px;
    background: #FAFAFA;
    text-align: left;
    cursor: pointer;
    margin-bottom: 20px;
    padding: 15px;
}
.lesson-item[data-v-2dc40be7]:hover, .classes-item[data-v-2dc40be7]:hover {
    -webkit-box-shadow: 0 0 5px #ddd;
            box-shadow: 0 0 5px #ddd;
}
.lesson-item[data-v-2dc40be7]:nth-child(3n) {
    margin-right: 0;
}
.lesson-item img[data-v-2dc40be7] {
    height: 190px;
    -o-object-fit: cover;
       object-fit: cover;
    margin-bottom: 10px;
    border-radius: 4px;
}
.lesson-item-1x[data-v-2dc40be7] {
    width: 285px;
    height: 316px;
    margin-right: 20px;
    background: #FAFAFA;
    text-align: left;
    cursor: pointer;
    margin-bottom: 20px;
    padding: 15px;
}
.lesson-item-1x[data-v-2dc40be7]:hover, .classes-item[data-v-2dc40be7]:hover {
    -webkit-box-shadow: 0 0 5px #ddd;
            box-shadow: 0 0 5px #ddd;
}
.lesson-item-1x[data-v-2dc40be7]:nth-child(4n) {
    margin-right: 0;
}
.lesson-item-1x img[data-v-2dc40be7] {
    height: 150px;
    -o-object-fit: cover;
       object-fit: cover;
    margin-bottom: 10px;
    border-radius: 4px;
}
.lesson-title[data-v-2dc40be7] {
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 24px;
    height: 24px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.lesson-about[data-v-2dc40be7] {
    margin-bottom: 22px;
    font-size: 14px;
    line-height: 20px;
    color: #666;
}
.lesson-name[data-v-2dc40be7] {
    min-height: 18px;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 18px;
    height: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.lesson-name span[data-v-2dc40be7] {
    height: 18px;
    line-height: 18px;
}
.lesson-price-info[data-v-2dc40be7] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.lesson-msg-wrap[data-v-2dc40be7] {
    background: #FAFAFA;
}
.lesson-price-sign[data-v-2dc40be7] {
    color: #FF5E57;
}
.lesson-price[data-v-2dc40be7] {
    color: #FF5E57;
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
}
.bot[data-v-2dc40be7] {
    -webkit-box-shadow: 0 3px 5px #ccc;
            box-shadow: 0 3px 5px #ccc;
}
.bot .first-menus[data-v-2dc40be7] {
    min-height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-transition: 1s all;
    transition: 1s all;
    padding-top: 18px;
}
.bot .first-menus li[data-v-2dc40be7] {
    color: #333;
    font-weight: bold;
    font-size: 16px;
    margin-right: 30px;
    margin-bottom: 18px;
    cursor: pointer;
    height: 24px;
    line-height: 24px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding-bottom: 2px;
    border-bottom: 2px solid #fff;
}
.second-menus[data-v-2dc40be7] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-transition: 1s all;
    transition: 1s all;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
}
.second-menus li[data-v-2dc40be7] {
    padding: 0 10px;
    /* height: 36px; */
    background: #fff;
    color: #333;
    font-size: 14px;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    line-height: 36px;
    text-align: left;
}
.third-menus[data-v-2dc40be7] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-transition: 1s all;
    transition: 1s all;
    margin-bottom: 20px;
}
.third-menus li[data-v-2dc40be7] {
    padding: 0 10px;
    /* height: 36px; */
    background: #fff;
    color: #333;
    font-size: 14px;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    line-height: 36px;
    text-align: left;
}
.loadding[data-v-2dc40be7] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.ac-bg[data-v-2dc40be7] {
    color: #0880F1!important;
    background: rgba(194,224,252, .5)!important;
    border-radius: 4px;
    font-weight: bold;
}
.ac[data-v-2dc40be7] {
    color: #0880F1!important;
    font-weight: bold;
}
.no[data-v-2dc40be7] {
    min-height: 400px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.no img[data-v-2dc40be7] {
    width: 100px;
    height: 100px;
}
.no p[data-v-2dc40be7] {
    color: #bbb;
}
.menu-more[data-v-2dc40be7] {
    color: #666;
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.menu-more img[data-v-2dc40be7] {
    width: 28px;
    height: 26px;
    padding: 5px;
    cursor: pointer;
}
