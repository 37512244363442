
.question[data-v-b97744fa] {
  padding: 20px;
  border: 1px solid #eee;
}
.title[data-v-b97744fa] {
  border-left: 4px solid #fff;
  text-align: left;
  padding-left: 10px;
  font-weight: bold;
  line-height: 20px;
  font-size: 16px;
  margin-bottom: 20px;
}
.list li[data-v-b97744fa] {
  border: 1px solid #efefef;
  padding: 30px 30px 0;
  border-radius: 6px;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
}
.list li:hover h3[data-v-b97744fa] {
  color: rgb(0, 113, 245);
}
.icon[data-v-b97744fa] {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  width: 50px;
  height: 20px;
  line-height: 20px;
  background: rgb(0, 113, 245);
}
.icon2[data-v-b97744fa] {
  position: absolute;
  left: 55px;
  top: 0;
  padding:0 10px;
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  height: 20px;
  line-height: 20px;
  background: rgb(255, 134, 17);
}
.list li h3[data-v-b97744fa] {
  font-size: 14px;
  color: #555;
  font-weight: bold;
  text-align: left;
  margin-bottom: 5px;
  -webkit-transition: all .3s;
  transition: all .3s;
}
.list li p[data-v-b97744fa] {
  text-align: left;
  color: #999;
  font-size: 12px;
}
